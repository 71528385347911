<template>
    <div class="col-6 col-xs-12 col-md-8">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-select
                        :items="years"
                        class="mt-0 mb-0 filter-width-130"
                        dense
                        hide-details="auto"
                        solo
                        v-model="filterOptions.year"
                        @change="filterList()"
                    ></v-select>
                    <v-select
                        :items="salesGroups"
                        :placeholder="$t('message.all')"
                        class="mt-0 mb-0 ml-3 filter-width-200"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="filterOptions.salesGroup"
                        @change="filterList()"
                    ></v-select>
                    <v-select
                        :items="allSalesColleagues"
                        :placeholder="$t('message.all')"
                        class="mt-0 mb-0 ml-3 filter-width-200"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Salesperson.name"
                        item-value="Salesperson.id"
                        solo
                        v-model="filterOptions.salesColleague"
                        @change="filterList()"
                    ></v-select>
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 ml-3 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="deliveryAddresses"
                        table-id="deliveryAddressesTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateSalesBudgetDialog()"
                    ></ExportTable>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.salesBudgets"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="filteredSalesBudgets"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="['SalesBudget.Contract.year','SalesBudget.SalesGroup.name','SalesBudget.SalesContact.name','SalesBudget.SalesType.title','SalesBudget.Contract.month']"
            :sort-desc="[true,false,false,false,false]"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="deliveryAddressesTable"
            item-key="SalesBudget.id"
            multiple-sort
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allSalesBudgets.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.SalesBudget.Contract.year="{ item }">
                <span class="font-weight-bold">{{ item.SalesBudget.Contract.year }}</span>
            </template>
            <template v-slot:item.SalesBudget.Contract.month="{ item }">
                <span>{{ getMonth(item.SalesBudget.Contract.month) }}</span>
            </template>
            <template v-slot:item.SalesBudget.total_intake_sales="{ item }">
                <span>{{ formatThisNumber(item.SalesBudget.total_intake_sales) }}</span>
            </template>
            <template v-slot:item.SalesBudget.total_intake_margin="{ item }">
                <span>{{ formatThisNumber(item.SalesBudget.total_intake_margin) }}</span>
            </template>
            <template v-slot:item.SalesBudget.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditSalesBudgetDialog(item.SalesBudget.id, item)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateSalesBudget') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteSalesBudget(item.SalesBudget.id, item)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteSalesBudget') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddSalesBudget
            :sales-budget-id="selectedSalesBudgetId"
            :sales-budget="selectedSalesBudget"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="salesBudgetUpdated"
            @create-done="salesBudgetCreated"
        ></AddSalesBudget>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from 'vuex'
import {numberFormat, log} from "Helpers/helpers";

const AddSalesBudget = () => import("Components/Appic/AddSalesBudget");
const ExportTable = () => import("Components/Appic/ExportTable");

export default {
    name: "SalesBudgetsListing",
    components: { AddSalesBudget, ExportTable },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filteredSalesBudgets: [],
            filterOptions: {
                salesColleague: null,
                salesGroup: null,
                year: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                salesBudgets: false
            },
            salesGroups: [
                {value: 1, text: 'Malaysia'},
                {value: 2, text: 'Thailand'},
                {value: 3, text: 'Vietnam'},
                {value: 4, text: 'Indonesia'},
                {value: 5, text: 'China'},
                {value: 998, text: 'Export'},
                {value: 999, text: 'Others'}
            ],
            searchField: null,
            searchTerm: null,
            selectedSalesBudgetId: null,
            selectedSalesBudget: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalSalesBudgets: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('salesbudget', {
            allSalesBudgets: 'allSalesBudgets'
        }),
        ...mapGetters('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters(['months']),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'SalesBudget.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.year'),
                    value: 'SalesBudget.Contract.year',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.salesGroup'),
                    value: 'SalesBudget.SalesGroup.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.salesColleague'),
                    value: 'SalesBudget.SalesContact.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.salesType'),
                    value: 'SalesBudget.SalesType.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.month'),
                    value: 'SalesBudget.Contract.month',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.salesAmount'),
                    value: 'SalesBudget.total_intake_sales',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.marginAmount'),
                    value: 'SalesBudget.total_intake_margin',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        },
        years(){
            let years = []
            let start = 2021
            let end = new Date().getFullYear();
            let i
            for(i = end; i >= start; i--){
                years.push({value: i, text: i.toString()})
            }
            return years
        }
    },
    methods: {
        ...mapActions('salesbudget',{
            deleteSalesBudgetById: 'deleteSalesBudgetById',
            getAllSalesBudgets: 'getAllSalesBudgets',
            resetCurrentState: 'resetCurrentState',
            resetState: 'resetState'
        }),
        ...mapActions('salescolleague', {
            getAllSalesColleagues: 'getAllSalesColleagues',
        }),
        async deleteSalesBudget ( val, item ) {
            let title = []
            title.push(item.SalesBudget.Contract.year)
            let month = this.months.find(m => m.Month.id == item.SalesBudget.Contract.month)
            title.push(month.Month.text)
            let salesColleague = this.allSalesColleagues.find(sc => sc.Salesperson.id == item.SalesBudget.salescontact_id)
            title.push(salesColleague.Salesperson.name)
            if(await this.$root.$confirm(this.$t('message.deleteSalesBudget') + ': ' +  (title.filter(Boolean).join(' : ')), this.$t('message.confirmations.continueSalesBudgetCancelAction'), {color: 'orange'})){
                this.deleteSalesBudgetById(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.salesBudgetDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getAllSalesBudgets()
                                .then(() => {
                                    this.filterList()
                                })
                        } else {
                            this.$toast.error(this.$t('message.errors.salesBudgetNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.salesBudgetNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterList  () {
            if(this.loading.salesBudgets == false) this.loading.salesBudgets = true
            let filteredSalesBudgets = this.allSalesBudgets.filter(sb => {
                return sb.SalesBudget.Contract.year == this.filterOptions.year
            })
            if(this.filterOptions.salesGroup != null){
                let tmpResults = filteredSalesBudgets.filter(fsb => {
                    return fsb.SalesBudget.destination_id == this.filterOptions.salesGroup
                })
                if(tmpResults) filteredSalesBudgets = tmpResults
            }
            if(this.filterOptions.salesColleague != null){
                let tmpResults = filteredSalesBudgets.filter(fsb => {
                    return fsb.SalesBudget.salescontact_id == this.filterOptions.salesColleague
                })
                if(tmpResults) filteredSalesBudgets = tmpResults
            }
            this.filteredSalesBudgets = filteredSalesBudgets
            if(this.loading.salesBudgets == true) this.loading.salesBudgets = false
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getMonth ( monthId ) {
            let month = this.months.find(m => m.Month.id == monthId)
            if(month){
                return month.Month.text
            }
            return ''
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateSalesBudgetDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditSalesBudgetDialog ( salesBudgetId, item) {
            let title = []
            title.push(item.SalesBudget.Contract.year)
            let month = this.months.find(m => m.Month.id == item.SalesBudget.Contract.month)
            title.push(month.Month.text)
            let salesColleague = this.allSalesColleagues.find(sc => sc.Salesperson.id == item.SalesBudget.salescontact_id)
            title.push(salesColleague.Salesperson.name)
            this.selectedSalesBudgetId = salesBudgetId
            this.selectedSalesBudget = title.filter(Boolean).join(' : ')
            this.updateMode = true
            this.editDialog = true
        },
        salesBudgetCreated () {
            this.editDialog = false;
            this.resetState()
            this.resetCurrentState()
            this.$emit('reload')
        },
        salesBudgetUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateSalesBudgetDialog()
            }
        }
    },
    created() {
        this.loading.salesBudgets = true
        this.resetState()
        this.filterOptions.year = new Date().getFullYear();
        if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
        if(this.allSalesBudgets.length == 0) {
            this.getAllSalesBudgets()
                .then(() => {
                    this.filterList()
                    this.loading.salesBudgets = false
                })
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.filter-width-130 {
    width: 130px !important;
    max-width: 130px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>